import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Escritor bohemio sumamente reflexivo e introspectivo, que nos presenta escenas de romance, profundamente íntimas, pero a la vez dolorosas, al pensar en el encuentro de dos almas rotas en una noche oscura, donde sólo queda el pasado después de la muerte. Sus textos de corte poético nos permiten adentrarnos y visualizar un momento, congelado para siempre en una imagen literaria.`}</h3>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1648393073/Screenshot_2022-03-27_at_16.57.25_dqm86q.png",
        "alt": "Ximena JPG"
      }}></img></p>
    <blockquote>
      <blockquote parentName="blockquote">
        <h1 parentName="blockquote">{`Este sitio`}</h1>
        <p parentName="blockquote">{`… amor, yo canto
mi electricidad
a todo riel entre
mis nervios
como una soledad
hecha tren bala…`}</p>
      </blockquote>
    </blockquote>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`hay devastación
en la memoria
que da la vuelta
al sol noche tras noche
y habla en psicosis
con el páramo
de ausencias bajo
sábanas de morgue…`}</p>
      </blockquote>
    </blockquote>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`amor, yo siento
la ternura
del corazón, acero
o plomo elemental
de una palabra
furtiva hecha colmillo
al calor de la forja`}</p>
      </blockquote>
    </blockquote>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`…esta es la velocidad
de la ceguera, fundición
de cosas rotas
o crucifijo amarrado
de dos cuerpos
detenidos
a la distancia
y la centrífuga
fuerza de las oraciones`}</p>
      </blockquote>
    </blockquote>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`…amor, esta es la ley,
resolución, sentencia
y pena,
viaje eterno,
purgatorio sin infierno
ni paraíso más allá
de las puertas`}</p>
      </blockquote>
    </blockquote>
    <h1>{`Juego fantasma`}</h1>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1648393000/poema2_1_b6ep0f.png",
        "alt": "Ximena JPG"
      }}></img></p>
    <p>{`¿Cuál era la forma de nuestra sombra?`}</p>
    <p>{`La luz rebotaba en unos pocos árboles que olvidó comer la ciudad, caía desde vidrios que cortaban el cielo, red invisible que de vez en cuando mataba a algún pájaro. La noche anterior era confusa en la memoria. Camino al parque, algunos autos lampareaban nuestra mirada con el golpe de sus faros, ¿veníamos ebrios?, llegamos al páramo con una botella de algo rasposo que perfumó nuestros alientos al amparo de una hoguera improvisada con periódicos y ramas.`}</p>
    <p><strong parentName="p">{`Entregamos el corazón a la distancia, en el hotel de paredes carcomidas, saboreando nuestras pieles. Deslizábamos las manos a través de los sueños del otro, sedientos de cariño, enfermos y desesperados, cada sonido se perdió en el vacío de las sábanas infectas.`}</strong>{` La débil llamarada deformaba nuestras caras, el beso de la botella consumía instantes de silencio, calle semidesierta, alguno que otro insomne despistado. Hablar no era preciso, las palabras morirían, como los peces, al tocar el viento.`}</p>
    <p>{`Salimos del hotel a comprar una botella, pan y jamón, ya no volvimos. Con los productos en mano, paso a paso jugábamos a la libertad. La noche, ¿qué era la noche sino lejanía? Por un momento cayeron al centro de nuestras pupilas el cansancio y reflejos de luna. Los titilantes latidos de su pecho se detuvieron lentamente, la ya diminuta flama de la fogata marchitó su combustible. ¿Dormías entonces o ya no pudiste dormir?`}</p>
    <p>{`Examiné cada una de las contraindicaciones en la receta de Nora, compramos lo necesario. Después de la oficina, incineramos, en un cenicero, la receta que nos dio el médico.`}</p>
    <p>{`Recuerdo la primera vez que estuvimos ahí, ella me convenció de entrar al hotel, y yo sin un quinto, lo pagó y después amanecimos con aroma de ron barato y llorando de alegría. Esta vez Nora guardó silencio en la recepción espectral de aquella ruina. `}<strong parentName="p">{`Quién sabe cuántos años han pasado, la vida nos dejó las almas rotas; la tomé del brazo y subimos al aposento mugroso donde conocimos la pasión una vez, cuando éramos jóvenes.`}</strong></p>
    <p>{`La madrugada se tambalea sobre cristales y ramazones podridos. Nora está recostada, pero se ha ido. Me hago un sándwich como cada mañana antes de salir de casa. Muerdo el pan con la respiración contenida, en espera de alguien que descubra nuestros cuerpos. Hallará una muerte pequeña junto al cadáver fracasado de un hombre que amó a cierta mujer enferma.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      